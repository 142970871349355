import { useRef, useState } from "react"
import { getColor } from "../../util/dom"
import RGIModal from "./RGIModal"
import RGIIcon from "./RGIIcon"
import RGIButton from "./RGIButton"
import Captcha from "./Captcha"

const Header = () => {
    const [ headerModal, setHeaderModal ] = useState<any>()
    const headerLogo = require("../../assets/images/rgi-round.png")
    const modalContent = useState<any>(
        <div className="row">
            <div className="col s12 center">
                <br />
                <div className="rgi-input-label">Email</div>
                <input id="header-signin-email" type="text" className="rgi-input browser-default" />
                <br />
                <br />
                <Captcha  />
                <br />
                <br />
                <RGIButton action={() => alert("Coming Soon")} label="Sign-In / Up" id="header-modal-signin-button" />
            </div>
        </div>
    )
    /**
     * The function `showLoginModal` opens a login modal using the `headerModal` object.
     */
    function showLoginModal(){
        headerModal.open()
    }

    return (
        <>
            <RGIModal 
                id="header-modal" 
                content={modalContent}
                title="Welcome home!" 
                instance={setHeaderModal} 
            />
            <div className="navbar-fixed">
                <nav style={{backgroundColor: getColor("steel")}}>
                    <div className="nav-wrapper">
                        <a href="/" className="brand-logo left">
                            <img className="header-logo" src={headerLogo} alt="RGI" />
                            <span className="header-text">RGI</span>
                        </a>
                        {
                        /*
                        <RGIIcon 
                            id="header-signin-button" 
                            action={showLoginModal} 
                            icon="account_circle"
                            align="right"
                            size="40"
                        />
                        */
                        }   
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header