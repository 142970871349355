import { useEffect, useRef } from "react"

/**
 * The `RGIButton` component is a TypeScript React button component that takes in props such as id,
 * label, color, align, and action, and triggers a buttonClickAction function when clicked.
 */
const RGIButton = ({id, label, color, align, action}:{id:string, label:any, color?:string, align?:string, action?:Function}) => {
  //FUNCTIONS
  function buttonClickAction(){
    const thisButton = document.getElementById(id) as HTMLElement
    if ( thisButton ){
        thisButton.blur()
    }
    if ( action ){
        action()   
    }
  }
  //RENDER
  return (
    <>
        <button id={id} onClick={buttonClickAction} className={"rgi-button " + color + " " + align}>{label}</button>
    </>
  )
}

export default RGIButton