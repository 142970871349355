import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './util/stylesheet.css'
import Landing from "./views/Landing";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
    </BrowserRouter>
  );
}

const webappRoot = document.getElementById("root") as HTMLElement
const root = ReactDOM.createRoot(webappRoot);
root.render(<App />);