import Header from "../components/_Global/Header"
import LandingSplash from "../components/Landing/LandingSplash"

const Landing = () => {
  return (
    <>
      <Header />
      <LandingSplash />
    </>
  )
}

export default Landing