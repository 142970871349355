import { useEffect } from "react"

const RGIModal = ({id, title, content, footer, options, instance}:{id:string, title?:string, content:any, footer?:any, options?:any, instance?:Function}) => {
  useEffect(() => {
    const thisModalElement = document.getElementById(id) as HTMLElement
    const thisModal = M.Modal.init(thisModalElement, options)
    if ( instance ){
      instance(thisModal)
    }
  }, [])
  return (
    <>
      <div id={id} className="modal row">
        <div className="modal-content">
          <div className="rgi-modal-title col s12 center"><br />{title}<br /><br /></div>
          {content}
        </div>
        <div className="modal-footer">
          <div className="rgi-modal-footer">{footer}</div>
        </div>
      </div>
    </>
  )
}

export default RGIModal