/**
 * The function `getColor` retrieves a custom color value from the CSS stylesheet based on the provided
 * color name.
 * @param {string} colorName - The `colorName` parameter is a string that represents the name of the
 * color you want to retrieve from the CSS custom properties.
 * @returns The `getColor` function is returning the value of a CSS custom property that corresponds to
 * the provided `colorName`. The custom property is retrieved from the computed style of the
 * `document.body` element.
 */
export function getColor(colorName:string):string{
    const stylesheet = getComputedStyle(document.body)
    return stylesheet.getPropertyValue('--rgi-color-'+colorName)
}