import { SocialIcon } from "react-social-icons"

const LandingSplash = () => {
  const landingSplashImage = require("../../assets/images/rgi-banner-large-transparent-centered.png")
  return (
    <>
        <div className="rgi-landing-splash" style={{backgroundImage: "url('"+landingSplashImage+"')"}}>
            Join the conversation
            <br />
            <SocialIcon url="https://discord.gg/4A7eHTW2s9" /> &nbsp;
            <SocialIcon url="https://www.reddit.com/r/RazorbackGaming/" /> &nbsp;
            <div style={{height: "50vh"}}></div>
            Follow our Founder
            <br />
            <SocialIcon url="https://reddit.com/u/rgi_casterly" /> &nbsp;
            <SocialIcon url="https://youtube.com/@RGICasterly" /> &nbsp;
            <SocialIcon url="https://x.com/RGICasterly" /> &nbsp;
            <SocialIcon url="https://twitch.tv/RGICasterly" /> &nbsp;
        </div>
    </>
  )
}

export default LandingSplash