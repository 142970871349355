import { captcha_sitekey } from "../../util/constants"

const Captcha = () => {
  return (
    <div className="g-recaptcha-container">
        <div className="g-recaptcha" data-sitekey={captcha_sitekey}></div>
    </div>
  )
}

export default Captcha